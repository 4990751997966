

























































// Core
import { Component, Prop, Vue } from 'vue-property-decorator'

// Interfaces
import { Project } from '@store/projects/interfaces'

@Component({
  name: 'Project',
})
export default class ProjectComponent extends Vue {
  @Prop({ default: () => null }) private project!: Project

  @Prop(Function)
  private handleDisconnectUserToProject!: (projectId: number) => void
}
